function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var helpers = require("../../utils/helpers");

function clearTaskStoreHandler(self) {
  if (self._delayRender) {
    self._delayRender.$cancelTimeout();
  }

  if (!self.$gantt) {
    return;
  }

  var tasks = self.$gantt.$data.tasksStore;
  var ownStore = self.$config.rowStore;
  var handlerIdProperty = "_attached_" + ownStore.$config.name;

  if (self[handlerIdProperty]) {
    tasks.detachEvent(self[handlerIdProperty]);
    self[handlerIdProperty] = null;
  }

  if (ownStore.$attachedResourceViewHandler) {
    ownStore.detachEvent(ownStore.$attachedResourceViewHandler);
    ownStore.$attachedResourceViewHandler = null;
    tasks.detachEvent(ownStore.$attachedTaskStoreHandler);
    ownStore.$attachedTaskStoreHandler = null;
  }
}

function createMixin(_super) {
  var initGrid = _super.prototype.init,
      destroyGrid = _super.prototype.destructor;
  return {
    init: function init() {
      initGrid.apply(this, arguments);

      this._linkToTaskStore();
    },
    destructor: function destructor() {
      clearTaskStoreHandler(this);
      destroyGrid.apply(this, arguments);
    },
    previousDragId: null,
    relevantResources: null,
    _linkToTaskStore: function _linkToTaskStore() {
      if (this.$config.rowStore && this.$gantt.$data.tasksStore) {
        var tasks = this.$gantt.$data.tasksStore;
        var ownStore = this.$config.rowStore;
        clearTaskStoreHandler(this);
        var self = this;
        var delayRender = helpers.delay(function () {
          if (self.$gantt.getState().lightbox) {
            delayRender();
          } else {
            var linkedStore = self.$config.rowStore; // Repaint only relevant resources for task drag

            var repaintStack = self._getRelevantResources();

            if (repaintStack) {
              if (repaintStack == "nothing_to_repaint") {
                return;
              }

              linkedStore._quick_refresh = true;
              self.relevantResources.forEach(function (id) {
                linkedStore.refresh(id);
              });
              linkedStore._quick_refresh = false;
            } else {
              // because rowstore could be changed during timeout
              linkedStore.refresh();
            }
          }
        }, 300);
        this._delayRender = delayRender;
        var handlerIdProperty = "_attached_" + ownStore.$config.name;

        if (!self[handlerIdProperty]) {
          self[handlerIdProperty] = tasks.attachEvent("onStoreUpdated", function () {
            if (!delayRender.$pending && !this._skipResourceRepaint) {
              // don't repaint if we update progress
              var state = self.$gantt.getState();

              if (state.drag_mode == "progress") {
                return true;
              } else if (state.drag_mode && state.drag_id) {
                // we need it here because if you started dragging a task and quickly stopped the drag_id is removed 
                // from the state before we record it, so we cannot repaint only the relevant resources
                self.previousDragId = state.drag_id;
              }

              delayRender();
            }

            return true;
          });
        }

        this.$gantt.attachEvent("onDestroy", function () {
          // detach events to don't call delayed tasks
          clearTaskStoreHandler(self);
          return true;
        });

        if (!ownStore.$attachedResourceViewHandler) {
          ownStore.$attachedResourceViewHandler = ownStore.attachEvent("onBeforeFilter", function () {
            if (self.$gantt.getState().lightbox) {
              return false;
            }

            if (delayRender.$pending) {
              delayRender.$cancelTimeout();
            }

            self._updateNestedTasks();

            return true;
          });
          ownStore.$attachedTaskStoreHandler = tasks.attachEvent("onAfterDelete", function () {
            ownStore._mark_recompute = true;
          });
        }
      }
    },
    _getRelevantResources: function _getRelevantResources() {
      // GS-2199. process_resource_assignments is disabled, 
      // so we cannot get only the relevant assignment resources
      if (!this.$gantt.getTaskAssignments) {
        return null;
      }

      var state = this.$gantt.getState();
      var linkedStore = this.$config.rowStore;
      var assignments = [];

      if (state.drag_mode && state.drag_id) {
        if (this.previousDragId == state.drag_id) {
          // we continue dragging the task
          if (this.relevantResources) {
            return this.relevantResources;
          } else {
            assignments = this.$gantt.getTaskAssignments(this.previousDragId);
          }
        } else {
          // we started the task drag
          this.previousDragId = state.drag_id;
          assignments = this.$gantt.getTaskAssignments(this.previousDragId);
        }
      } else if (this.previousDragId) {
        // finished task drag, but the resources are still the same
        assignments = this.$gantt.getTaskAssignments(this.previousDragId);
        this.previousDragId = null;
      } else {
        return null;
      }

      if (!assignments.length) {
        return this.relevantResources = "nothing_to_repaint";
      }

      var resourceIds = [];
      assignments.forEach(function (assignment) {
        resourceIds.push(assignment.resource_id);
        linkedStore.eachParent(function (parent) {
          resourceIds.push(parent.id);
        }, assignment.resource_id);
      }); // eslint-disable-next-line

      return this.relevantResources = _toConsumableArray(new Set(resourceIds));
    },
    _updateNestedTasks: function _updateNestedTasks() {
      var gantt = this.$gantt;
      var resourceStore = gantt.getDatastore(gantt.config.resource_store);

      if (!resourceStore.$config.fetchTasks) {
        return;
      }

      resourceStore.silent(function () {
        var toAddArray = [];
        var toAdd = {};
        var toDelete = {};
        resourceStore.eachItem(function (resource) {
          if (resource.$role == "task") {
            toDelete[resource.id] = true;
            return;
          }

          var assignments = gantt.getResourceAssignments(resource.id);
          var addedTasks = {}; // GS-1505. We need to sort assignments before updating tasks. 
          // Iterating them without that will affect the order of featched tasks in the resource store

          assignments.sort(function (a, b) {
            var resourceData = resourceStore.pull;
            var resource1 = resourceData["".concat(a.task_id, "_").concat(a.resource_id)];
            var resource2 = resourceData["".concat(b.task_id, "_").concat(b.resource_id)];

            if (resource1 && resource2) {
              return resource1.$local_index - resource2.$local_index;
            } else {
              return 0;
            }
          });
          assignments.forEach(function (a) {
            if (addedTasks[a.task_id]) {
              return;
            }

            addedTasks[a.task_id] = true;
            var task = gantt.getTask(a.task_id); //var copy = gantt.copy(task);

            var copy = Object.create(task);
            copy.id = task.id + '_' + resource.id;
            copy.$task_id = task.id;
            copy.$resource_id = resource.id;
            copy[resourceStore.$parentProperty] = resource.id;
            copy.$role = "task";
            toAddArray.push(copy);
            toAdd[copy.id] = true;
          });
        });

        for (var id in toDelete) {
          if (!toAdd[id]) {
            resourceStore.removeItem(id);
          }
        }

        if (toAddArray.length) {
          resourceStore.parse(toAddArray);
        }
      });
    }
  };
}

module.exports = createMixin;